import { NextPageContext } from 'next';
import {useRouter} from "next/router";
import { AuthUser, MenuResponse } from 'types';
import { getAuthUser, getMenuData } from 'api';
import { getCookies } from 'utils/services';
import { PERMISSION_LIST } from 'consts';
import { ErrorPage, ErrorPageProps } from 'containers/ErrorPage';

function Error({ menuData, url, user }: ErrorPageProps) {
  const router = useRouter();

  return (
    <ErrorPage
      menuData={menuData}
      url={url}
      user={user}
      primaryButton={{
        caption: 'Back to Homepage',
        action: () => router.push('/'),
      }}
    />
  );
}

export async function getServerSideProps({ err, req, res }: NextPageContext) {
  const cookies = { parsed: getCookies(req) };
  const userRequest: Promise<AuthUser> = getAuthUser(cookies);
  const menuDataRequest: Promise<MenuResponse> = getMenuData();
  const [menuData, user] = await Promise.all([menuDataRequest, userRequest]);

  if (user && user.role === 'super_admin' && !user.permissions?.length) {
    user.permissions = PERMISSION_LIST;
  }

  const statusCode = res?.statusCode || err?.statusCode || 404;
  const url = req?.url;

  return {
    props: {
      statusCode,
      menuData: menuData || [],
      url,
      user: user || {},
    },
  };
}

export default Error;
